import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

function ProgressReport({ member }) {
  console.log('member', member)
  const data = useStaticQuery(graphql`
    query {
      allPrismicHistory {
        nodes {
          data {
            progress_reports {
              text {
                text
              }
              group_client {
                url
              }
            }
            time(locale: "")
          }
        }
      }
    }
  `)
  console.log('ProgressReport data', data.allPrismicHistory.nodes)

  const progressReport = data.allPrismicHistory.nodes.map((node) => {
    if (node.data.progress_reports.length) {
      console.log('node', node)
      return (
        <ul key={node.key}>
          {node.data.progress_reports.map((report) => {
            report.time = node.data.time
            if (report.group_client.url === member) {
              console.log('report', report)
              return (
                <li key={report.text.text}>
                  {new Date(report.time).toLocaleDateString('de-DE')}:{' '}
                  {report.text.text}
                </li>
              )
            }
          })}
        </ul>
      )
    }
  })

  return <div>{progressReport}</div>
}

export default ProgressReport
