import React from 'react'
import { Row, Col } from 'react-bootstrap'

function TwoColSection({ leftColContent, rightColContent }) {
  return (
    <Row>
        <Col>{leftColContent}</Col>
        <Col>{rightColContent}</Col>
    </Row>
  )
}

export default TwoColSection