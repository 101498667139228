import React from 'react'
import { repositoryConfigs } from '../utils/prismicPreviews'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'
import { Row, Col } from 'react-bootstrap'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'
import TwoColSection from '../components/TwoColSection'
import SEO from '../components/seo'
import ProgressReport from '../components/ProgressReport'

const MemberTemplate = (data) => {
  console.log('alldata', data)
    const memberData = data.data.prismicMember.data
    console.log('member data', memberData)

  return (
    <LayoutAnleitungen>
      <SEO index="noindex" />
      <h1>{data.data.prismicMember.url}</h1>
      <ProgressReport member={data.data.prismicMember.url} />
    </LayoutAnleitungen>
  ) 
}

export const query = graphql`
  query memberQuery($id: String, $lang: String) {
    prismicMember(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        abbr
        fitness
      }
    }
  }
`
export default withPrismicPreview(MemberTemplate, repositoryConfigs)
